import { Frame, Code, addPropertyControls, ControlType } from "framer"

function MyComponent({ code }) {
    return (
        <Frame>
            <div
                style={{
                    width: "100%",
                    height: "400px",
                    backgroundColor: "#f4f4f4",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    overflow: "auto",
                    padding: "16px",
                }}
            >
                <Code
                    language="javascript"
                    text={code}
                    style={{
                        fontFamily: "monospace",
                        fontSize: "14px",
                        lineHeight: "1.4",
                    }}
                />
            </div>
        </Frame>
    )
}

addPropertyControls(MyComponent, {
    code: {
        type: ControlType.String,
        title: "Código JavaScript",
        defaultValue: `
      // Código JavaScript inicial
      console.log('Hello, World!');
    `,
        displayTextArea: true,
    },
})

export default MyComponent
